<template>
  <div
      class="container inside-wrapper inside-wrapper-margin"
      id="case-study"
      data-aos="fade-up"
      data-aos-duration="1000"
  >

        <div class="row idaay-background-img back-white" style="">
            <div class="col-md-5 col-heading">
                <h2 class="heading wow fadeInDown text-blue">IDAAY <a href="http://idaay.org/" target="_blank"><span class="lnr lnr-link"></span></a></h2>
                <br>
                <p class="text-dark wow fadeInUp text-dark text-justify">
                    The Institute For the Development of African American Youth Inc. provides educational and cultural programs as well as prevention-intervention social services for youth, their families, and the broader community via a combination of carefully designed programs. ORT is proud to provide technical consulting and digital marketing to IDAAY.
                    <br><br>
                    Member of our Non-Profit Donation program. <a class="text-blue" href="/Support">Learn More</a>
                </p>
                <br><br>
                <a href="http://idaay.org/" class="web-blue" target="_blank"><span class="web-icon-blue"><i class="fa fa-globe "></i> </span> <span class="web-text-blue text-bold">View Website</span></a>
                <br><br><br>
            </div>
        </div>
        <section class="case-study">

            <div class="row">
                <div class="col-sm-12">
                    <div class="row margin-row-off">
                        <div class="col-sm-6">
                            <div class="margin-heading-top">

                                <h2 class="heding-title">1<span class="sub-title-pad sub-title-text">Challenge</span></h2>
                            </div>

                            <p class="text-dark wow fadeInUp text-justify">
                                The Institute For the Development of African American Youth Inc. is a nationally recognized organization based in Philadelphia, Pennsylvania; and, it was created expressly to empower and educate low income, disadvantaged at-risk urban youth to succeed.
                                The organization was in need of rebranding of their site and materials after more than 25 years in existence. This meant designing a new logo that represented IDAAY's culture among the young people they wanted to reach and empower as well as improve
                                the presentation of their events, staff and message to a group of people becoming increasingly more technologically advanced.
                            </p>

                        </div>
                        <div class="col-sm-6">
                            <div class="app-screens">
                                <img width="100%" src="../../assets/img/casestudy-idaay/casestudy001.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="app-screens hidden-xs">
                                <img width="100%" src="../../assets/img/casestudy-idaay/casestudy002.jpg">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class=" margin-heading-top">
                                <h2 class="heding-title">2<span class="sub-title-pad sub-title-text">Obstacles</span></h2>
                            </div>
                            <ul type="none" class="contactPoints">
                                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Applications were primarily handled by mail or in person</span></li>
                                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Tracking of data was not easy due to the wordpress template utilized</span></li>
                                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Events were hard to find and not always up to date on the website</span></li>
                                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>The site was not representative of a professional and involved organization</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="margin-heading-top">
                                <h2 class="heding-title">3<span class="sub-title-pad sub-title-text">Solution</span></h2>

                            </div>
                            <p class="text-dark wow fadeInUp text-justify">
                                ORT was tasked with rebranding of IDAAY's identity and assisting in identifying the areas of the site that needed to be updated immediately in order to make it easier for visitors to find information.
                                The site needed to be more professional and allow for any potential donors to understand what IDAAY represented and the impact they were making in the community.
                                <br>
                                Using our experience in social media branding we began various campaigns to build the audience of IDAAY and increase the communities knowledge of their available programs and thus increasing the number
                                of applicants as well as event attendees. Their monthly newsletter is a source of community interactions and contributions by other organizations in the area.
                                <br><br>
                            </p>
                        </div>
                        <div class="col-sm-6">
                            <div class="app-screens hidden-xs">
                                <img width="100%" src="../../assets/img/casestudy-idaay/casestudy003.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="app-screens">
                                <img width="100%" src="../../assets/img/casestudy-idaay/casestudy004.jpg">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="margin-heading-top">
                                <h2 class="heding-title">4<span class="sub-title-pad sub-title-text">Benefits</span></h2>
                            </div>
                            <p class="text-dark wow fadeInUp text-justify">
                                When it comes to providing support to organizations that help our communities, the benefit speaks for itself. IDAAY can reach more young people and provide their services while also using the data they capture to
                                see the impact they are making and which programs have the biggest positive effect. Feedback about staff and services are easier to gather and analyze as well as attendance of events and programs increases. By
                                providing our expertise to an organization through an indirect sponsorship, IDAAY can achieve more with less and continue to utilize their funds for more important opportunities.
                                <br><br>
                            </p>
                        </div>


                    </div>
                    <div class="col-sm-8  col-technologies text">
                        <h2 class="text-dark border-dotted-blue">Whichever services you require, we have the skills to match.</h2>
                        <router-link to="/contact" class="btn-red hvr-sweep-to-right hvr-sweep-to-right-red">Contact</router-link>
                    </div>
                </div>
            </div>
        </section>

               <div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>
            </div>
</template>

<script>
export default {
  name: "work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
